import React from 'react';
import '../styles/CCSalesSummary.scss';
import CCSalesSummaryCard from './CCSalesSummaryCard';

const CCSalesSummary = () => {

    const data = [
        { id: 1, title: "Total Today Sale", price: 1500, color: "#913311" },
        { id: 2, title: "Weekly Total Sale", price: 150000, color: "#EF5E00" },
        { id: 3, title: "Current month sale", price: 15000, color: "#0FCB53" },
        { id: 4, title: "Previuos Month Sale", price: 154000, color: "#42A6F3" },
    ];

    return (
        <div id="cc-sales-summary-container" className='container-fluid mb-4 p-0'>
            <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-4 ">
                {data.map((sales_items, index) => <CCSalesSummaryCard
                    key={sales_items.id}
                    sales_items={sales_items}

                ></CCSalesSummaryCard>)}


            </div>
        </div>
    );
};

export default CCSalesSummary;