import React from 'react';
import "../styles/CCSalesSummaryCard.scss";

const CCSalesSummaryCard = ({ sales_items }) => {
    return (
        <div className="col">
            <div className="card h-100 " id='cc-sales-summary-card' style={{ backgroundColor: `${sales_items.color}` }}>
                <div className="card-body">
                    <h5 >{sales_items.title}</h5>
                    <p>{sales_items.price}</p>
                </div>
            </div>
        </div>
    );
};

export default CCSalesSummaryCard;