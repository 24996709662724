import React from 'react';
import { Outlet } from 'react-router-dom';
import CCHeader from '../components/CCHeader';
import CCReviews from '../components/CCReviews';
import CCSalesSummary from '../components/CCSalesSummary';
import CCSideBar from '../components/CCSideBar';
import CCSubHeader from '../components/CCSubHeader';
import CCWeeklySales from '../components/CCWeeklySales';
import "../styles/CCHome.scss";

const CCHome = () => {
    return (
        <div className="container-fluid" id='cc-home'>
            <div className="row ">
                <div className="col-12 col-sm-12 col-md-3 col-lg-2 p-0 home-left">
                    <CCSideBar></CCSideBar>
                </div>
                <div className="col-12 col-sm-12 col-md-9 col-lg-10 px-4 home-right">
                    <CCHeader></CCHeader>
                    {/* <CCReviews></CCReviews> */}
                    {/* <CCSubHeader></CCSubHeader>
                    <CCSalesSummary></CCSalesSummary>
                    <CCWeeklySales></CCWeeklySales> */}
                    <Outlet></Outlet>
                </div>
            </div>
        </div>
    );
};

export default CCHome;