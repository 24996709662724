import React from 'react';
import "../styles/CCStaffTileCard.scss";

const CCStaffTileCard = ({ titles, remainingStaff, selectedStaff }) => {
    return (
        <div className="col">
            <div className="card h-100 py-5" id='cc-stuff-title-card' style={{ backgroundColor: `${selectedStaff.id === titles.id ? "#913311" : titles.color}` }} role={"button"}
                onClick={() => remainingStaff(titles.id)}
            >
                <div className="card-body">
                    <h5 >{titles.title}</h5>

                </div>
            </div>
        </div>
    );
};

export default CCStaffTileCard;