import React from 'react';
import '../styles/CCSubHeader.scss';
import subImg from "../assets/images/sub-header.png";

const CCSubHeader = () => {

    return (
        <div id="cc-sub-header-container" className='container-fluid mb-4'>
            <div className="left-items">
                <p className='cc-sub-header-title-1'>Welcome back, <span>Cloud Cafe-1</span></p>
                <p className='cc-sub-header-title-2'>You have:<span> 12 Pending orders</span></p>
                <button className='button-style'>View Orders</button>
            </div>
            <img src={subImg} alt="" className='img-fluid' />
        </div>
    );
};

export default CCSubHeader;