import React from 'react';
import CCStaffTileCard from './CCStaffTileCard';

const CCStaffTitleContainer = ({ staff_info, remainingStaff, selectedStaff }) => {

    const data = [
        { id: 1, title: "Manager", color: "#BC0E37" },
        { id: 2, title: "Barista", color: "#EF5E00" },
        { id: 3, title: "Delivery Boy", color: "#0FCB53" },

    ];
    return (
        <div id="cc-staff-title-container" className='container-fluid mb-4 p-0'>
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-3 g-4 ">
                {staff_info.map((titles, index) => <CCStaffTileCard
                    key={titles.id}
                    titles={titles}
                    remainingStaff={remainingStaff}
                    selectedStaff={selectedStaff}

                ></CCStaffTileCard>)}


            </div>
        </div>
    );
};

export default CCStaffTitleContainer;