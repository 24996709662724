import React from 'react';
import ReactStars from 'react-stars'
import "../styles/CCReviews.scss";
import img from "../assets/images/table-img.png";
import { useLocation } from 'react-router-dom';

const CCReviews = ({ setPath }) => {

    let location = useLocation();

    return (
        <div className="container-fluid" id='cc-review-table'>

            <div className="table-responsive ">
                <table className="table align-middle table-borderless m-0 w-100" >
                    <thead >
                        <tr >
                            <th scope="col">S/N</th>
                            <th scope="col">Image</th>
                            <th scope="col" className='w-25'>Customer Name</th>
                            <th scope="col" className='w-25'>Rating</th>
                            <th scope="col" className='w-25'>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td><img src={img} alt="" className='img-fluid rounded-circle' width={36} /></td>
                            <td>Rafeed Khan</td>
                            <td >
                                <ReactStars
                                    count={5}
                                    value={4}
                                    edit={false}
                                    size={24}
                                    color2={'#A57939'}
                                />
                            </td>
                            <td>Lorem Ipsum is simply dummy lorem10 </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td><img src={img} alt="" className='img-fluid rounded-circle' width={36} /></td>
                            <td>Rafeed Khan</td>
                            <td >
                                <ReactStars
                                    count={5}
                                    value={4}
                                    edit={false}
                                    size={24}
                                    color2={'#A57939'}
                                />
                            </td>
                            <td>Lorem Ipsum is simply dummy </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td><img src={img} alt="" className='img-fluid rounded-circle' width={36} /></td>
                            <td>Rafeed Khan</td>
                            <td >
                                <ReactStars
                                    count={5}
                                    value={4}
                                    edit={false}
                                    size={24}
                                    color2={'#A57939'}
                                />
                            </td>
                            <td>Lorem Ipsum is simply dummy </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td><img src={img} alt="" className='img-fluid rounded-circle' width={36} /></td>
                            <td>Rafeed Khan</td>
                            <td >
                                <ReactStars
                                    count={5}
                                    value={4}
                                    edit={false}
                                    size={24}
                                    color2={'#A57939'}
                                />
                            </td>
                            <td>Lorem Ipsum is simply dummy </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td><img src={img} alt="" className='img-fluid rounded-circle' width={36} /></td>
                            <td>Rafeed Khan</td>
                            <td >
                                <ReactStars
                                    count={5}
                                    value={4}
                                    edit={false}
                                    size={24}
                                    color2={'#A57939'}
                                />
                            </td>
                            <td>Lorem Ipsum is simply dummy </td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default CCReviews;
