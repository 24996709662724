import React, { useState } from 'react';
import "../styles/CCSideBar.scss";
import logo from "../../Dashboard/assets/images/arabika-logo.png";
import { RiDashboardFill } from 'react-icons/ri';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { MdNotificationImportant, MdMenuBook } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import { RiFileList3Line } from 'react-icons/ri';
import { BiMessageRoundedDetail } from 'react-icons/bi';
import { Link } from 'react-router-dom';


const CCSideBar = () => {

    const [selected, setSelected] = useState("home");

    return (
        <div className=' py-3' id='cc-sidebar-container'>
            <img src={logo} alt="" className='img-fluid my-3 d-block m-auto' />

            <div className="all-side-menu">
                <Link to={"/home"} className="text-decoration-none">
                    <div className={`cc-side-menu ${selected === "home" ? "selected-style" : ""}`} onClick={() => setSelected("home")}>
                        <div className="cc-side-menu-items p-3">
                            <RiDashboardFill className='inline-block cc-menu-items-icon'></RiDashboardFill>
                            <h5>Dashboard</h5>
                        </div>
                    </div>
                </Link>
                <div className="cc-side-menu">
                    <div className="cc-side-menu-items p-3">
                        <AiOutlineUnorderedList className='inline-block cc-menu-items-icon'></AiOutlineUnorderedList>
                        <h5>Order</h5>
                    </div>
                </div>

                <Link to={"/notification"} className="text-decoration-none">
                    <div className={`cc-side-menu ${selected === "notification" ? "selected-style" : ""}`} onClick={() => setSelected("notification")}>
                        <div className="cc-side-menu-items p-3">
                            <MdNotificationImportant className='inline-block cc-menu-items-icon'></MdNotificationImportant>
                            <h5>Notification</h5>
                        </div>
                    </div>
                </Link>
                <Link to={"/staff-info"} className="text-decoration-none">
                    <div className={`cc-side-menu ${selected === "staff-info" ? "selected-style" : ""}`} onClick={() => setSelected("staff-info")}>
                        <div className="cc-side-menu-items p-3">
                            <FaUsers className='inline-block cc-menu-items-icon'></FaUsers>
                            <h5>Staff Information</h5>
                        </div>
                    </div>
                </Link>

                <div className="cc-side-menu">
                    <div className="cc-side-menu-items p-3">
                        <MdMenuBook className='inline-block cc-menu-items-icon'></MdMenuBook>
                        <h5>Menu</h5>
                    </div>
                </div>
                <div className="cc-side-menu">
                    <div className="cc-side-menu-items p-3">
                        <RiFileList3Line className='inline-block cc-menu-items-icon'></RiFileList3Line>
                        <h5>All Report</h5>
                    </div>
                </div>

                <Link to={"/reviews"} className="text-decoration-none">
                    <div className={`cc-side-menu ${selected === "reviews" ? "selected-style" : ""}`} onClick={() => setSelected("reviews")}>
                        <div className="cc-side-menu-items p-3">
                            <BiMessageRoundedDetail className='inline-block cc-menu-items-icon'></BiMessageRoundedDetail>
                            <h5>Review</h5>
                        </div>
                    </div>
                </Link>




            </div >

        </div >
    );
};

export default CCSideBar;