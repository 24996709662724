import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import CCHome from './Dashboard/Pages/CCHome';
import './App.scss';
import CCMain from './Dashboard/Pages/CCMain';
import CCReviews from './Dashboard/components/CCReviews';
import CCNotification from './Dashboard/Pages/CCNotification';
import CCStaffInfo from './Dashboard/Pages/CCStaffInfo';
import CCStaffDetailsInfo from './Dashboard/components/CCStaffDetailsInfo';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<CCHome />} >
          <Route path="" element={<CCMain />} />
          <Route path="home" element={<CCMain />} />
          <Route path="reviews" element={<CCReviews />} />
          <Route path="notification" element={<CCNotification />} />
          <Route path="staff-info" element={<CCStaffInfo />} />
          <Route path="staff-details-info" element={<CCStaffDetailsInfo />} />
        </Route>

      </Routes>
    </div>
  );
}

export default App;
