import React from 'react';
import img from '../assets/images/staff-details.png';
import '../styles/CCStaffDetailsInfo.scss';

const CCStaffDetailsInfo = () => {

    return (
        <div className='container-fluid p-5' id='cc-staff-details-info'>
            <h1>Stuff<span> Information</span></h1>

            <div className="row align-items-center pt-3">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                    <img src={img} alt="" className='img-fluid py-3 d-block m-auto w-100' />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9">

                    <div class="table-responsive">
                        <table class="table table-borderless">
                            {/* <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">First</th>
                                    <th scope="col">Last</th>
                                    <th scope="col">Handle</th>
                                </tr>
                            </thead> */}
                            <tbody>
                                <tr>
                                    <td className='w-25'>Name:</td>
                                    <td>Rafeed Khan</td>
                                </tr>
                                <tr>
                                    <td className='w-25'>Email:</td>
                                    <td>rafeed@gmail.com</td>
                                </tr>
                                <tr>
                                    <td className='w-25' >Phone:</td>
                                    <td>01705588139</td>
                                </tr>
                                <tr>
                                    <td className='w-25'>DOB:</td>
                                    <td>01-01-1988</td>
                                </tr>
                                <tr>
                                    <td className='w-25' >NID:</td>
                                    <td>6356123416346</td>
                                </tr>
                                <tr>
                                    <td className='w-25' >Shift Hour:</td>
                                    <td>08am -16pm</td>
                                </tr>
                                <tr>
                                    <td className='w-25'>Status:</td>
                                    <td>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                            <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default CCStaffDetailsInfo;