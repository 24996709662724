import React from 'react';
import avatar from "../assets/images/user-icon.png";
import "../styles/CCHeader.scss";
import { MdNotifications } from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';

const CCHeader = () => {
    return (
        <div id="cc-header-container" className='container-fluid py-4 mb-4'>
            <h1><span>Cloud</span> Cafe</h1>
            <div className="header-right-info">
                <div className="d-flex align-items-center">
                    <div className='cc-header-icon-container'>
                        <AiOutlineSearch className="inline-block cc-header-icon me-3"></AiOutlineSearch>
                        <button type="button" className="header-notify-btn position-relative border-0">
                            <MdNotifications className="inline-block cc-header-icon  " >

                            </MdNotifications>
                            <span className="position-absolute  translate-middle p-1 bg-primary border  rounded-circle">

                            </span>
                        </button>
                    </div>
                    <p>Cloud Cafe-1</p>
                    <img src={avatar} alt="" className='img-fluid' />
                </div>
            </div>
        </div>
    );
};

export default CCHeader;