import React, { useState } from 'react';
import CCStaffTable from '../components/CCStaffTable';
import CCStaffTitleContainer from '../components/CCStaffTitleContainer';
import image from '../assets/images/table-img.png';

const CCStaffInfo = () => {

    const staff_info = [
        {
            id: 1,
            title: "Manager",
            color: "#BC0E37",
            manager_staff: [
                {
                    id: 1,
                    image: image,
                    name: "Rafeed Khan",
                    phone: "01705588139",
                    comment: "Lorem Ipsum is simply dummy"
                },
                {
                    id: 2,
                    image: image,
                    name: "Sumon Khan",
                    phone: "01705588139",
                    comment: "Lorem Ipsum is simply dummy"
                },
            ]
        },
        {
            id: 2,
            title: "Barista",
            color: "#EF5E00",
            manager_staff: [
                {
                    id: 5,
                    image: image,
                    name: "Taseen Bappi",
                    phone: "01705588139",
                    comment: "Lorem Ipsum is simply dummy"
                },
            ]
        },
        {
            id: 3,
            title: "Delivery Boy",
            color: "#0FCB53",
            manager_staff: [
                {
                    id: 6,
                    image: image,
                    name: "Shafiq Rahman",
                    phone: "01705588139",
                    comment: "Lorem Ipsum is simply dummy"
                },
            ]
        }
    ];

    const [selectedStaff, setSelectedStaff] = useState(staff_info[0]);

    const remainingStaff = (id) => {
        const staff = staff_info.find((staff_info) => staff_info.id === id);
        return setSelectedStaff(staff);

    }


    return (
        <div className='container-fluid'>
            <CCStaffTitleContainer staff_info={staff_info} remainingStaff={remainingStaff} selectedStaff={selectedStaff}></CCStaffTitleContainer>
            <CCStaffTable selectedStaff={selectedStaff}></CCStaffTable>
        </div>
    );
};

export default CCStaffInfo;