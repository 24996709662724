import React, { useState } from 'react';
import Chart from "react-apexcharts";
import "../styles/CCWeeklySales.scss";

const CCWeeklySales = () => {

    const [weeklySales, setWeeklySales] = useState({

        series: [{
            data: [400, 430, 448, 470, 540, 580, 690]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 380,
                foreColor: '#FFFFFF'
            },

            legend: {
                show: false,
            },

            plotOptions: {
                bar: {
                    barHeight: '100%',
                    distributed: true,
                    horizontal: false,
                    dataLabels: {
                        position: 'bottom'
                    },
                }
            },
            colors: ['#42A6F3', '#9747FF', '#006747', '#FFD24D', '#EF5E00', '#EC4F3C', '#0FCB53'
            ],
            dataLabels: {
                enabled: false,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },

                offsetX: 0,
                dropShadow: {
                    enabled: false
                }
            },

            xaxis: {
                categories: ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri',

                ],
            },
            yaxis: {
                labels: {
                    show: true
                }
            },
            title: {
                text: 'This Week Sale',
                align: 'left',
                floating: false,
                style: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: 'Mulish',
                    color: '#FFFFFF'
                },
            },

            tooltip: {
                theme: 'dark',
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            }
        },

    }
    );

    return (
        <div className="container-fluid mb-4 " id='cc-weekly-sales-container'>
            <div className="row p-3">
                <div className="mixed-chart">
                    <Chart
                        options={weeklySales.options}
                        series={weeklySales.series}
                        type="bar"
                        height="360"
                        width="100%"
                    />
                </div>
            </div>
        </div>
    );
};

export default CCWeeklySales;