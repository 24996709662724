import React from 'react';
import CCCreateNotification from '../components/CCCreateNotification';
import CCNotificationTable from '../components/CCNotificationTable';

const CCNotification = () => {

    return (
        <div className='container-fluid'>
            <CCCreateNotification></CCCreateNotification>
            <CCNotificationTable></CCNotificationTable>
        </div>
    );
};

export default CCNotification;