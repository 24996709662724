import React from 'react';
import "../styles/CCStaffTable.scss";
import img from "../assets/images/table-img.png";
import { Link } from 'react-router-dom';

const CCStaffTable = ({ selectedStaff }) => {

    const staffs = selectedStaff?.manager_staff;

    return (
        <div className="container-fluid" id='cc-staff-table'>

            <div className="table-responsive ">
                <table className="table align-middle table-borderless m-0 w-100" >
                    <thead >
                        <tr >
                            <th scope="col">S/N</th>
                            <th scope="col">Image</th>
                            <th scope="col" >Name</th>
                            <th scope="col">Phone No</th>
                            <th scope="col" className='w-25'>Comment</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedStaff?.manager_staff?.map((staff) => <tr key={staff.id}>
                            <th scope="row">{staff.id}</th>
                            <td><img src={img} alt="" className='img-fluid rounded-circle' width={36} /></td>
                            <td>{staff?.name}</td>
                            <td>01705588139</td>
                            <td>Lorem Ipsum is simply dummy </td>
                            <td>
                                <Link to={"/staff-details-info"} className="details-link">Details</Link>
                            </td>
                        </tr>)}



                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default CCStaffTable;