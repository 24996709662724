import React, { useEffect, useId, useState } from 'react';
import "../styles/CCCreateNotification.scss";
import { MdDeleteForever } from 'react-icons/md';

const CCCreateNotification = () => {
    const id = useId();

    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);

    // console.log(file);
    // console.log(fileDataURL);

    const imageReadHandler = () => {

        if (file) {
            const fileReader = new FileReader();
            fileReader.onChange = (e) => {
                const { result } = e.target;
                if (result) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }

    }

    const uploadImgDeleteHandler = () => {

        // eslint-disable-next-line no-use-before-define
        const status = confirm("Are you sure!!"); //eslint-disable-line
        if (status) {
            setFile(null);
        }
    }


    useEffect(() => {

        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }

    }, [file]);





    return (
        <div className='container-fluid' id='cc-create-notification'>

            <h1>Create New <span>Notification</span></h1>

            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <div className="row p-3 align-items-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            {file === null ? <div className="notify-img-container mt-3">
                                <label htmlFor="formFile" className="form-label m-0" role={"button"}>+</label>
                                <input className="form-control d-none" type="file" accept="image/*" id="formFile"
                                    onChange={(e) => {
                                        setFile(e.target.files[0]);
                                        imageReadHandler(e);
                                    }} />

                            </div> : <div className="notify-img-file-container mt-3">

                                <img src={fileDataURL} alt="" className='img-fluid d-block' height={200} />
                                <button className='delete-img-btn' onClick={() => uploadImgDeleteHandler()}>
                                    <MdDeleteForever className='fs-4'></MdDeleteForever>
                                </button>

                            </div>}
                            {file === null && <p className='notify-add-img-txt'>Add Images</p>}
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                            <div className="mb-3">
                                <label htmlFor={id} className="form-label label-title-txt">Title</label>
                                <input type="text" className="form-control notify-input-field" id={id} placeholder="Notify title here" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor={id} className="form-label label-title-txt">Description</label>
                                <textarea className="form-control notify-input-field" id={id} rows="3" placeholder="Description"></textarea>
                            </div>
                            <button className='button-style'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CCCreateNotification;