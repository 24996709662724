import React from 'react';
import img from "../assets/images/notify.png";
import "../styles/CCNotficationTable.scss";

const CCNotificationTable = () => {
    return (
        <div className="container-fluid mt-3" id='cc-notification-table'>

            <div className="table-responsive ">
                <table className="table align-middle table-borderless m-0 w-100" >
                    <thead >
                        <tr >
                            <th scope="col">S/N</th>
                            <th scope="col">Image</th>
                            <th scope="col" className='w-25'>Title</th>
                            <th scope="col" className='w-25'>Date</th>
                            <th scope="col" className='w-25'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td><img src={img} alt="" className='img-fluid ' width={98} /></td>
                            <td>Buy 1 get 1</td>
                            <td >
                                12/21/22
                            </td>
                            <td>Lorem Ipsum is simply dummy lorem10 </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td><img src={img} alt="" className='img-fluid ' width={98} /></td>
                            <td>Buy 1 get 1</td>
                            <td >
                                12/21/22
                            </td>
                            <td>Lorem Ipsum is simply dummy lorem10 </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td><img src={img} alt="" className='img-fluid ' width={98} /></td>
                            <td>Buy 1 get 1</td>
                            <td >
                                12/21/22
                            </td>
                            <td>Lorem Ipsum is simply dummy lorem10 </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td><img src={img} alt="" className='img-fluid ' width={98} /></td>
                            <td>Buy 1 get 1</td>
                            <td >
                                12/21/22
                            </td>
                            <td>Lorem Ipsum is simply dummy lorem10 </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td><img src={img} alt="" className='img-fluid ' width={98} /></td>
                            <td>Buy 1 get 1</td>
                            <td >
                                12/21/22
                            </td>
                            <td>Lorem Ipsum is simply dummy lorem10 </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td><img src={img} alt="" className='img-fluid ' width={98} /></td>
                            <td>Buy 1 get 1</td>
                            <td >
                                12/21/22
                            </td>
                            <td>Lorem Ipsum is simply dummy lorem10 </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td><img src={img} alt="" className='img-fluid ' width={98} /></td>
                            <td>Buy 1 get 1</td>
                            <td >
                                12/21/22
                            </td>
                            <td>Lorem Ipsum is simply dummy lorem10 </td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default CCNotificationTable;