import React from 'react';
import { useLocation } from 'react-router-dom';
import CCSalesSummary from '../components/CCSalesSummary';
import CCSubHeader from '../components/CCSubHeader';
import CCWeeklySales from '../components/CCWeeklySales';

const CCMain = ({ setParams }) => {

    let location = useLocation();

    return (
        <>
            <CCSubHeader></CCSubHeader>
            <CCSalesSummary></CCSalesSummary>
            <CCWeeklySales></CCWeeklySales>
        </>
    );
};

export default CCMain;